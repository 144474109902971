<template>
  <div v-loading="globalLoading">
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>项目结算报表</span>
      </div>
      <div>
      </div>
    </div>
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"

    />

    <div class="total_salary1">
      <div class="s1">项目总额合计：<i>{{ a1 }}</i>万元</div>
      <div>（进行中的项目已收款:<i>{{ a2 }}</i>万元 ， 未收款:<i>{{ a3 }}</i>万元，合计:<i>{{ a4 }}</i>万元）</div>
    </div>

    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @handleSizeChange="handleSizeChange"
        @detileLeave="detileLeave"
        @passOrder="passOrder"
        @done="done"

    />

    <!--项目收款dialog-->
    <el-dialog
        title="项目收款"
        :visible.sync="projectCollectionData.show"
        :append-to-body="true"
    >
      <div v-loading="projectCollectionData.loading">
        <!--        <el-button type="primary" icon="el-icon-plus el-icon&#45;&#45;left" @click="addMember" style="margin-bottom: 15px">
                  添加收款明细
                </el-button>-->
        <div style="margin-bottom: 15px">项目成单金额：{{ projectCollectionData.total }}万元</div>
        <el-table
            :data="projectCollectionData.tableData"
            border
            style="width: 100%"
        >


          <el-table-column
              header-align="center"
              align="center"
              label="期数"
              type="index"
          >
          </el-table-column>

          <el-table-column
              header-align="center"
              align="center"
              label="金额(万元)"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.expect_amount }}</span>
            </template>
          </el-table-column>

          <el-table-column
              header-align="center"
              align="center"
              label="实收款（万元）"
              label-class-name="required3"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">{{ scope.row.actual_amount }}</span>
              <el-input v-model="scope.row.actual_amount" placeholder="请输入实收款（万元)" v-else></el-input>
            </template>
          </el-table-column>

          <el-table-column
              header-align="center"
              align="center"
              label="实收日期"
              label-class-name="required3"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">{{ scope.row.actual_receive_time?Utils.timeDate(Math.trunc(scope.row.actual_receive_time/1000)):'' }}</span>
              <el-date-picker type="date" placeholder="请选择日期" v-model="scope.row.actual_receive_time" value-format="timestamp"
                              style="width: 100%;"
                              v-else></el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
              header-align="center"
              align="center"
              label="操作"
              width="150px"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="editReceipt(scope.row,scope.$index)" v-if="scope.row.type==1">修改</el-button>
              <el-button type="primary" @click="subReceipt(scope.row,scope.$index)" v-else>确认收款</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="projectCollectionData.show=false">取 消</el-button>
      </div>

    </el-dialog>

    <!--分红结算dialog-->
    <el-dialog
        title="分红结算"
        :visible.sync="dividendSettlementData.show"
        :append-to-body="true"
    >
      <div v-loading="dividendSettlementData.loading">
        <el-button type="primary" icon="el-icon-plus el-icon--left" @click="addMember1" style="margin-bottom: 15px">
          添加分红结算
        </el-button>
        <el-table
            :data="dividendSettlementData.tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              header-align="center"
              align="center"
              label="累计分红基数金额（万元）"
              label-class-name="required3"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status!=1">{{ (scope.row.a1/10000).toFixed(2) }}</span>
              <el-input v-model="scope.row.a1" placeholder="请输入累计分红基数金额（万元）" v-else></el-input>
            </template>
          </el-table-column>

          <el-table-column
              header-align="center"
              align="center"
              label="分红日期"
              width="250px"
              label-class-name="required3"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status!=1">{{ Utils.timeDate(Math.floor(scope.row.a2 / 1000)) }}</span>
              <el-date-picker
                  v-model="scope.row.a2"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                  v-else
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
              header-align="center"
              align="center"
              label="操作"
              width="150px"
          >
            <template slot-scope="scope">
              <el-button type="danger" icon="el-icon-delete el-icon--left"
                         @click="deleteMember1(scope.row,scope.$index)" v-if="scope.row.status==1">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dividendSettlementData.show = false">取 消</el-button>
        <el-button type="primary" @click="addDialogSub1">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {getProjectType} from "../../../api/project_management/new_project";
import {projectSumPage, moneySettleDetail, setRedPrice, prmp, subPrmp} from "@/api/caicai/api";

export default {
  components: {},
  props: [],
  data() {
    return {
      globalLoading: true,
      a1: '0',//项目总额合计
      a2: '0',//进行中的项目已收款
      a3: '0',//未收款
      a4: '0',//合计

      formLabelWidth: "120px",

      //项目收款 dialog
      // dialogFormVisible: false, //项目收款dialog
      // form: {
      //   project_id: 0,
      //   first_price: 0,
      //   last_price: 0,
      //   real_all_price: 0,
      //   record_price_json: []
      // },
      projectCollectionData: {
        show: false,
        loading: true,
        total: 0,//项目成单金额

        project_id: 0,
        first_price: 0,//实收首款
        last_price: 0,//实收尾款
        real_all_price: 0,//实收总金额

        tableData: [
          /*{
            expect_amount: '10',//收款
            actual_amount: '10',//日期
            actual_receive_time: '',//备注说明
            type: 1,//1 可以输入和删除 2不可以
          }*/
        ]
      },

      //分红结算 dialog
      dividendSettlementData: {
        show: false,
        loading: false,
        project_id: '',
        tableData: [
          /*{
            a1: '',//收款
            a2: '',//日期
            status: 1,//1 可以输入和删除 2不可以
          }*/
        ]
      },

      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '项目名称',
          field: 'projectName',
          type: 1,
        }, {
          name: '项目类型',
          field: 'typeId',
          type: 3,
        }, {
          name: '项目状态',
          field: 'status',
          type: 3,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        // 状态 下拉的值-1=作废，0=待成单，1=成单，2=不成单，3=已指派（进行中，待完工），4=完工（待验收），5=验收（已完成）  ，6=不通过
        statusOptions: [
          {value: '-1', label: '作废'},
          {value: '0', label: '待成单'},
          {value: '1', label: '成单'},
          {value: '2', label: '不成单'},
          {value: '3', label: '已指派（进行中，待完工）'},
          {value: '4', label: '完工待验收'},
          {value: '5', label: '验收'},
          {value: '6', label: '不通过'},
        ],
        total_statusOptions: [
          {value: '1', label: '不成单'},
          {value: '2', label: '立项待审批'},
          {value: '3', label: '待成单'},
          {value: '4', label: '待揭榜'},
          {value: '5', label: '待完工'},
          {value: '6', label: '待验收'},
          {value: '7', label: '待结题-技术'},
          {value: '8', label: '待结题-市场'},
          {value: '9', label: '待结算'},
          {value: '10', label: '完成'},
        ],
        typeIdOptions: [],
        //高级搜索的字段
        heighKewWordField: {projectName: '', typeId: '', status: ''},
        //tabs 标题
        tabsData: [{
          label: '全部',
          name: '0',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },
        table: [{
          title: '项目编号',
          field: 'project_sn',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'type_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目成单金额(万元)',
          field: 'all_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '实收总金额(万元)',
          field: 'actual_amount_total',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '分红总金额(万元)',
          field: 'all_red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '人工成本(万元)',
          field: 'labor_cost',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '商务成本(万元)',
          field: 'reimburse_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '毛利(万元)',
          field: 'maoLi',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目状态',
          field: 'status_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '完成日期',
          field: 'end_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: true,
          width: '300px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 21,
          tableOperate: [{
            name: '结算明细',
            type: 'primary',
            method: 'detileLeave',
            status: '20000',
            id: 20000,
          }, {
            name: '项目收款',
            type: 'danger',
            method: 'passOrder',
            status: '20000',
            id: 20000,
          }, {
            name: '分红结算',
            type: 'warning',
            method: 'done',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {}
    };
  },
  created() {

    this.initLoad();
    this.getProjectType()
  },
  mounted() {
  },
  methods: {
    getProjectType() {
      getProjectType().then(res => {
        this.tabsProp.typeIdOptions = res.data.map(item => ({
          label: item.type_name,
          value: (item.id).toString()
        }))
      })
    },

    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      this.initLoad();
    },
    handleSizeChange(val) {
      this.tableProp.page.size = val
      this.initLoad();
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        per_page: this.tableProp.page.size,
      }
      if (this.tabsProp.heighKewWordField.projectName != "") {
        params.projectName = this.tabsProp.heighKewWordField.projectName
      }
      if (this.tabsProp.heighKewWordField.status != "") {
        params.status = this.tabsProp.heighKewWordField.status
      }
      if (this.tabsProp.heighKewWordField.typeId != "") {
        params.typeId = this.tabsProp.heighKewWordField.typeId
      }

      this.globalLoading = true;//加载中
      projectSumPage(params).then((result) => {
        this.a1 = (result.data.statsData.all_project_money / 10000).toFixed(2);//项目总额合计
        this.a2 = (result.data.statsData.ing_money / 10000).toFixed(2);//进行中的项目已收款
        this.a3 = (result.data.statsData.no_get_money / 10000).toFixed(2);//未收款
        this.a4 = (result.data.statsData.total_all_money / 10000).toFixed(2);//合计
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].all_red_price = this.tableData[i].project_price.all_red_price == null ? "0" : (this.tableData[i].project_price.all_red_price / 10000).toFixed(2)
          // this.tableData[i].maoLi = this.tableData[i].project_price.maoLi == null ? "0" : (this.tableData[i].project_price.maoLi / 10000).toFixed(2)
          this.tableData[i].reimburse_price = this.tableData[i].project_price.reimburse_price == null ? "0" : (this.tableData[i].project_price.reimburse_price / 10000).toFixed(2)
          this.tableData[i].labor_cost = this.tableData[i].project_price.labor_cost == null ? "0" : (this.tableData[i].project_price.labor_cost / 10000).toFixed(2)
          this.tableData[i].real_all_price = this.tableData[i].project_price.real_all_price == null ? "0" : (this.tableData[i].project_price.real_all_price / 10000).toFixed(2)
          this.tableData[i].maoLi=( Number(this.tableData[i].real_all_price)-Number(this.tableData[i].all_red_price)-Number(this.tableData[i].labor_cost)-Number(this.tableData[i].reimburse_price)).toFixed(2);
          // this.tableData[i].all_price = this.tableData[i].project_price.all_price == null ? "0" : (this.tableData[i].project_price.all_price / 10000).toFixed(2)
           this.tableData[i].all_price = (this.tableData[i].project_price.deal_all_price / 10000).toFixed(2)
          // 1=不成单，2=立项待审批，//3=待成单，4=待揭榜，5=待完工，6=待验收，7=待结题-技术，8=待结题-市场,9=待结算，10=完成
          switch (this.tableData[i].total_status) {
            case 1:
              this.tableData[i].status_name = "不成单";
              break;
            case 2:
              this.tableData[i].status_name = "立项待审批";
              break;
            case 3:
              this.tableData[i].status_name = "待成单";
              break;
            case 4:
              this.tableData[i].status_name = "待揭榜";
              break;
            case 5:
              this.tableData[i].status_name = "待完工";
              break;
            case 6:
              this.tableData[i].status_name = "待验收";
              break;
            case 7:
              this.tableData[i].status_name = "待结题-技术";
              break;
            case 8:
              this.tableData[i].status_name = "待结题-市场";
              break;
            case 9:
              this.tableData[i].status_name = "待结算";
              break;
            case 10:
              this.tableData[i].status_name = "完成";
              break;


          }
          this.tableData[i].type_name = '暂无信息'
          if (this.tableData[i].project_type) {
            this.tableData[i].type_name = this.tableData[i].project_type.type_name
          }
        }
        this.globalLoading = false;//加载中
      })
    },
    //tabs 切换点击
    tabsClick() {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {

      this.tableProp.page.current_page = 1;
      this.initLoad()
    },

    detileLeave(row) {
      this.$router.push({path: './settlement/detail', query: {id: row.id}})
    },

    //表格项目收款按钮 点击
    passOrder(row) {
      this.projectCollectionData = this.$options.data().projectCollectionData
      this.projectCollectionData.show = true;
      this.projectCollectionData.loading = true;
      prmp({pid: row.id}).then((res)=>{
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].type = 1
          if (res.data[i].actual_receive_time){
            res.data[i].actual_receive_time = res.data[i].actual_receive_time*1000
          }
        }
        this.projectCollectionData.tableData = res.data
        this.projectCollectionData.total = (row.project_price.deal_all_price / 10000).toFixed(2);//项目成单金额
        this.projectCollectionData.loading = false;
      })
      /*
      this.projectCollectionData.project_id = row.id;
      let tableData = row.project_price.record_price_json == null ? [] : JSON.parse(row.project_price.record_price_json);
      if (tableData.length > 0) {
        this.projectCollectionData.tableData = tableData.map(item => ({
          a1: item.price,//收款
          a2: item.date * 1000,//日期
          a3: item.remark,//备注说明
          status: 2,//1 可以输入和删除 2不可以
        }));
      } else {
        this.projectCollectionData.tableData = [];
      }

      this.projectCollectionData.first_price = row.project_price.first_price == null ? 0 : row.project_price.first_price;//实收首款
      this.projectCollectionData.last_price = row.project_price.last_price == null ? 0 : row.project_price.last_price;//实收尾款
      this.projectCollectionData.real_all_price = row.project_price.real_all_price == null ? 0 : row.project_price.real_all_price;//实收总金额*/
    },
    //项目收款修改按钮点击
    editReceipt(row){
      row.type = 2
    },
    //项目收款确认收款按钮点击
    subReceipt(row){
      console.log(row)
      let subData = {
        id : row.id,
        actual_amount : row.actual_amount,
        actual_receive_time : JSON.parse(JSON.stringify(row.actual_receive_time))/1000
      }
      //必填项判断
      if (!subData.actual_amount || !subData.actual_receive_time){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      subPrmp(subData).then(()=>{
        this.$message({
          message: '提交成功！',
          type: 'success'
        });
        row.type = 1
      })
    },


    //分红结算
    done(row) {
      this.dividendSettlementData.show = true;
      this.dividendSettlementData.loading = true;
      this.dividendSettlementData.project_id = row.id;

      let params = {
        project_id: this.dividendSettlementData.project_id
      }
      moneySettleDetail(params).then(res => {
        this.dividendSettlementData.tableData = res.data.map(item => ({
          a1: item.price,//收款
          a2: item.date * 1000,//日期
          status: 2,//1 可以输入和删除 2不可以
        }));
        this.dividendSettlementData.loading = false;
      })
    },

    // 分红结算dialog 添加按钮点击
    addMember1() {
      this.dividendSettlementData.tableData.push({
        a1: '',//收款
        a2: new Date().getTime(),//日期
        status: 1,//1 可以输入和删除 2不可以
      })
    },
    // 分红结算dialog 删除按钮点击
    deleteMember1(row, index) {
      console.log(index)
      console.log(row)
      this.dividendSettlementData.tableData.splice(index, 1)
    },
    //分红结算dialog 点击确认
    addDialogSub1() {
      let tableData = JSON.parse(JSON.stringify(this.dividendSettlementData.tableData))
      if (tableData.length == 0) {
        this.$message({
          message: '请添加分红结算',
          type: 'error'
        });
        return false
      }
      let red_array = [];
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].status == 1) {
          if (!tableData[i].a1 || !tableData[i].a2){
            this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          } else {
            let red_arrayJson = {
              price: tableData[i].a1,
              date: Math.floor(tableData[i].a2 / 1000),
            }
            red_array.push(red_arrayJson)
          }

        }
      }
      let data = {
        project_id: this.dividendSettlementData.project_id,
        red_array: red_array
      }
      setRedPrice(data).then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.dividendSettlementData.show = false;
        this.initLoad();
      })
    },

  },
};
</script>
<style lang="scss" scoped>
.total_salary1 {
  @include flex();

  div {
    @include flex();
  }

  .s1 {
    margin-right: 22px;
  }

  margin-bottom: 20px;
  font-size: 18px;

  i {
    color: red;
    display: inline-block;
    margin: 0 5px;
    font-size: 22px;
    font-weight: bold;
  }
}

.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
