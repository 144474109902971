import http from '../http';

// 项目结算报表列表
export function projectSumPage (params) {
    return http({
        url: "/api/project/projectSumPage",
        method: "get",
        params:params
    })
}

// 项目收款
export function prmp (params) {
    return http({
        url: "/api/project/prmp",
        method: "get",
        params:params
    })
}
// 项目收款
export function subPrmp (data) {
    return http({
        url: "/api/project/prmp",
        method: "post",
        data:data
    })
}


//分红结算 dialog
export function moneySettleDetail (params) {
    return http({
        url: "/api/project/projectRedRecord",
        method: "get",
        params:params
    })
}

//分红结算 提交
export function setRedPrice (data) {
    return http({
        url: "/api/project/setRedPrice",
        method: "post",
        data:data
    })
}